<template>
  <div class="singleMedia" v-if="this.$store.state.videos.singleMediaPage">
    <section class="latest-section">
      <div class="container">
        <h2 v-scroll-animation="'animate__fadeInDown'">{{ this.$store.state.videos.singleMediaPage.media.title }}</h2>
        <div class="row">
          <div class="col-lg-12 main-video" v-scroll-animation="'animate__zoomIn'"
               v-html="this.$store.state.videos.singleMediaPage.media.youtube_frame"></div>
        </div>
      </div>
    </section>
    <section class="program-episodes" v-if="this.$store.state.videos.singleMediaPage.others.length > 0">
      <div class="container">
        <h3 v-scroll-animation="'animate__fadeInDown'">حلقات أخرى</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6"
               v-scroll-animation="'animate__zoomIn'"
               v-for="item in this.$store.state.videos.singleMediaPage.others">
            <router-link :to="{name:'singleVideo',params:{id:item.id}}" class="episode-item">
              <img
                  class="img-fluid"
                  :src="$store.state.generalStore.urlPrefixForImages+item.featured_image"
                  alt="Episode Image"
              />
              <h5>{{ item.title }}</h5>
              <p>
                {{ item.short_description }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "singleMedia",
  watch: {
    '$route.params.id': {
      handler: function () {
        this.getMediaInfo();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getMediaInfo() {
      this.$store.dispatch("loading/show");
      this.$store.dispatch("videos/getSingleMediaInfo", {id: this.$route.params.id})
          .then(() => {
            this.$store.dispatch("loading/hide");
          })
          .catch((err) => {
            console.log(err);
          })
    },
  },
  created() {
    this.getMediaInfo();
  },
}
</script>

<style lang="scss">
.singleMedia {
  background-color: #fbfcfc;
  padding: 3em 0;

  .latest-section {
    margin-bottom: 4em;

    h2 {
      font-weight: 700;
      margin-bottom: 1.5em;
    }

    .main-video {
      iframe {
        width: 100%;
        height: 30em;
        border-radius: 8px;
        box-shadow: 0 0 12px rgba(#000000, 0.1);
      }
    }

    .sub-videos {
      //overflow-y: scroll;
      //height: 30em;
      .video-item {
        iframe {
          width: 100%;
          border-radius: 8px;
          height: 15em;
          margin-bottom: 0.5em;
        }
      }
    }
  }

  .program-episodes {
    margin-top: 3em;

    h3 {
      font-weight: 700;
      margin-bottom: 1.2em;
    }

    .episode-item {
      display: block;
      margin-bottom: 1.8em;

      img {
        border-radius: 8px;
        margin-bottom: 0.7em;
        width: 100%;
      }

      h5 {
        color: $myViolet;
        font-weight: 700;
      }

      p {
        color: #424242;
        font-size: 0.95rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .videos-page {
    .latest-section {
      .main-video {
        iframe {
          height: 18em;
          margin-bottom: 0.7em;
        }
      }

      .sub-videos {
        .video-item {
          iframe {
            height: 18em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .singleMedia {
    .latest-section {
      .main-video {
        iframe{
          height: 20em;
          height: auto;
          aspect-ratio: 16/9;
        }
      }
    }
    .program-episodes{
      h3{
        font-size: 2rem;
      }
    }
  }
}
</style>
<template>
  <div class="videos-page">
    <section class="latest-section" v-if="$store.state.videos.lastMedia.length>0">
      <div class="container">
        <h2 v-scroll-animation="'animate__fadeInDown'">الأحدث</h2>
        <div class="row">
          <div class="col-lg-8 main-video" v-bind:class="{'col-lg-12':$store.state.videos.lastMedia.length===1}"
               v-html="$store.state.videos.lastMedia[MainVideoIndex].youtube_frame"
               v-scroll-animation="'animate__zoomIn'"></div>
          <div class="col-lg-4 sub-videos" v-if="$store.state.videos.lastMedia.length>1">
            <div class="video-item" v-bind:key="item.id"
                 v-for="item in $store.state.videos.lastMedia.filter((value, index) => MainVideoIndex !== index)"
                 v-scroll-animation="'animate__zoomIn'"
                 @click="changeMainVideo($store.state.videos.lastMedia.indexOf(item))">
              <img class="img-fluid" :src="$store.state.generalStore.urlPrefixForImages+item.featured_image"
                   alt="Video Thumbnail" v-if="!isMobile"/>
              <div v-html="item.youtube_frame" v-else/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="program-episodes" v-bind:key="cat.id"
             v-for="cat in this.$store.state.videos.mediaCategoriesWithPosts">
      <div class="container">
        <h3 v-scroll-animation="'animate__fadeInDown'">{{ cat.name }}</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6" v-bind:key="post.id" v-for="post in cat.posts"
               v-scroll-animation="'animate__zoomIn'">
            <router-link :to="{name:'singleVideo',params:{id:post.id}}" class="episode-item">
              <img
                  class="img-fluid"
                  :src="$store.state.generalStore.urlPrefixForImages+post.featured_image"
                  alt="Episode Image"
              />
              <h5>{{ post.title }}</h5>
              <p>
                {{ post.short_description }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "videos",
  data() {
    return {
      MainVideoIndex: 0,
      isMobile:false,
    };
  },
  methods: {
    changeMainVideo(index) {
      this.MainVideoIndex = index;
    },
    // isMobile() {
    //   return window.matchMedia('(max-width: 992px)').matches;
    // },
    onResize() {
      this.isMobile = window.innerWidth < 991;
    },
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("videos/getAboutUsPageInfo")
        .then(() => {
          this.$store.dispatch("loading/hide");
        })
        .catch(err => {
          console.log(err);
        })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
  },
};
</script>

<style lang="scss">
.videos-page {
  background-color: #fbfcfc;
  padding: 3em 0;

  .latest-section {
    margin-bottom: 4em;

    h2 {
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: 1.5em;
    }

    .main-video {
      iframe {
        width: 100%;
        height: 30em;
        border-radius: 8px;
      }
    }

    .sub-videos {
      overflow-y: scroll;
      height: 31em;

      .video-item {
        iframe, img {
          cursor: pointer;
          width: 100%;
          border-radius: 8px;
          height: 15em;
          margin-bottom: 0.5em;
          object-fit: cover;
        }
      }
    }
  }

  .program-episodes {
    margin-top: 3em;

    h3 {
      font-weight: 700;
      margin-bottom: 1.2em;
    }

    .episode-item {
      display: block;
      margin-bottom: 1.8em;

      img {
        border-radius: 8px;
        margin-bottom: 0.7em;
      }

      h5 {
        color: $myViolet;
        font-weight: 700;
      }

      p {
        color: #424242;
        font-size: 0.95rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .videos-page {
    .latest-section {
      .main-video {
        iframe {
          //height: 18em;
          height: auto;
          aspect-ratio: 16/9;
          margin-bottom: 0.7em;
        }
      }

      .sub-videos {
        height: unset;
        .video-item {
          display: none;
          &:nth-child(1),&:nth-child(2){
            display: block;
          }
          iframe {
            height: 18em;
            object-fit: unset;
          }
        }
      }
    }
  }
}
</style>
